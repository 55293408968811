import React from "react";
import HarmonicWave from '../simulazioni/HarmonicWave.js'
import DoublePendulum from "../simulazioni/DoublePendulum.js";
import WaveInterference from "../simulazioni/WaveInterferene.js";

const Test = () => {
    return (
      /*   <div>
                This is the test page. <br /> No tests running at the moment.
        </div> */
        <WaveInterference />

    )
}

export default Test;
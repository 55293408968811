import logo from './logo.svg';
import './App.css';
import Navbar  from './components/Navbar.js'
import Didattica from './pages/Didattica.js'
import Articoli from './pages/Articoli.js'
import { Route, Routes,  BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/Footer.js';
import Lezione from './components/Lezione.js';
import Home from './components/Home.js';
import PaginaAnno from './pages/PaginaAnno.js';
import Simulazioni from './pages/Simulazioni.js';
import MotoParabolico from './simulazioni/MotoParabolico.js';
import MotoCircolare from './simulazioni/MotoCircolare.js';
import Cavendish from './simulazioni/Cavendish.js';
import Questionari from './pages/Questionari.js';
import UnitCircle from './simulazioni/UnitCircle.js';
import Fourier from './simulazioni/Fourier.js';
import HarmonicWave from './simulazioni/HarmonicWave.js';
import Test from './test/Test.js';
import DoublePendulum from './simulazioni/DoublePendulum.js';
import WaveInterference from './simulazioni/WaveInterferene.js';

function App() {
  return (
    <div className="App">
      {/* Sticky Navbar */}
      
      {/* Routes for navigation */}
      <Router>
      <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/didattica" element={<Didattica />} />
          <Route path="/articoli" element={<Articoli />} />
          <Route path="/simulazioni" element={<Simulazioni />} />
          <Route path="/questionari" element={<Questionari />} />
          <Route path="/lezioni/:materia/:anno" element={<PaginaAnno />} />
          <Route path="/lezione/:id" element={<Lezione id/>} /> {/* ID lezi  one */}


          <Route path="/simulazioni/moto-parabolico" element={<MotoParabolico />} />
          <Route path="/simulazioni/moto-circolare" element={<MotoCircolare />} />
          <Route path="/simulazioni/cavendish" element={<Cavendish />} />
          <Route path="/simulazioni/unit-circle" element={<UnitCircle />} />
          <Route path="/simulazioni/fourier" element={<Fourier />} />
          <Route path="/simulazioni/harmonic-wave" element={<HarmonicWave />} />
          <Route path="/simulazioni/double-pendulum" element={<DoublePendulum />} />
          <Route path="/simulazioni/interferenza" element={<WaveInterference />} />

          <Route path="/test" element={<Test />} />
        </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;

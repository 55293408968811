import React, { useRef, useEffect, useState } from "react";

const WaveInterference = () => {
  const canvasRef = useRef(null);
  const [frequency1, setFrequency1] = useState(0.06); // Frequenza dell'onda 1
  const [frequency2, setFrequency2] = useState(0.05); // Frequenza dell'onda 2
  const [amplitude1, setAmplitude1] = useState(50); // Ampiezza dell'onda 1
  const [amplitude2, setAmplitude2] = useState(50); // Ampiezza dell'onda 2
  const [speed, setSpeed] = useState(0.05); // Velocità di animazione

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;

    let time = 0;

    const draw = () => {
      ctx.clearRect(0, 0, width, height);

      ctx.fillStyle = "#2f4f4f"; // Sfondo scuro
      ctx.fillRect(0, 0, width, height);

      // Disegna l'onda 1
      ctx.strokeStyle = "#ff6347"; // Colore dell'onda 1 (rosso)
      ctx.lineWidth = 1;
      ctx.beginPath();
      for (let x = 0; x < width; x++) {
        const y1 = amplitude1 * Math.sin(frequency1 * x - time);
        const y = (height / 2) + y1;
        if (x === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();

      // Disegna l'onda 2
      ctx.strokeStyle = "#1e90ff"; // Colore dell'onda 2 (blu)
      ctx.lineWidth = 1;
      ctx.beginPath();
      for (let x = 0; x < width; x++) {
        const y2 = amplitude2 * Math.sin(frequency2 * x - time);
        const y = (height / 2) + y2;
        if (x === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();

      // Disegna l'onda risultante
      ctx.strokeStyle = "#00c194"; // Colore dell'onda risultante (verde)
      ctx.lineWidth = 2;
      ctx.beginPath();
      for (let x = 0; x < width; x++) {
        const y1 = amplitude1 * Math.sin(frequency1 * x - time);
        const y2 = amplitude2 * Math.sin(frequency2 * x - time);
        const y = (height / 2) + y1 + y2; // Somma delle due onde
        if (x === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();

      time += speed;
      requestAnimationFrame(draw);
    };

    draw();
    return ()=>{cancelAnimationFrame(requestAnimationFrame(draw))}
  }, [frequency1, frequency2, amplitude1, amplitude2, speed]);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <canvas
        ref={canvasRef}
        width={window.innerWidth}
        height={window.innerHeight * 0.75}
        style={{ display: "block", margin: "0 auto", backgroundColor: "#2f4f4f" }}
      ></canvas>
      <div style={{ marginTop: "20px" }}>
        <div>
          <label>Frequenza Onda 1: </label>
          <input
            type="range"
            min="0.05"
            max="0.15"
            step="0.01"
            value={frequency1}
            style={{ accentColor: "#ff6347" }}
            onChange={(e) => setFrequency1(parseFloat(e.target.value))}
          />
        </div>
        <div>
          <label>Frequenza Onda 2: </label>
          <input
            type="range"
            min="0.05"
            max="0.15"
            step="0.01"
            value={frequency2}
            style={{ accentColor: "#1e90ff" }}
            onChange={(e) => setFrequency2(parseFloat(e.target.value))}
          />
        </div>
        <div>
          <label>Ampiezza Onda 1: </label>
          <input
            type="range"
            min="10"
            max="50"
            step="5"
            value={amplitude1}
            style={{ accentColor: "#ff6347" }}
            onChange={(e) => setAmplitude1(parseFloat(e.target.value))}
          />
        </div>
        <div>
          <label>Ampiezza Onda 2: </label>
          <input
            type="range"
            min="10"
            max="50"
            step="5"
            value={amplitude2}
            style={{ accentColor: "#1e90ff" }}
            onChange={(e) => setAmplitude2(parseFloat(e.target.value))}
          />
        </div>
        <div>
          <label>Velocità: </label>
          <input
            type="range"
            min="0.01"
            max="0.25"
            step="0.01"
            value={speed}
            onChange={(e) => setSpeed(parseFloat(e.target.value))}
          />
        </div>
      </div>
    </div>
  );
};

export default WaveInterference;
